<template>
	<div class="container2">

		<div class="mapcontainer2">
			<!-- <polygonpoint  :zoom="zoom" :center="center" :detail="detail"></polygonpoint>	 -->
			<leaflet-polygonpoint  :zoom="zoom" :center="center" :detail="detail"></leaflet-polygonpoint>	
		</div>
		<div class="swipercontainer2">
			<div class="zhibiaotitle">
				地块规划指标
			</div>
			<el-table class="tabledata" :data="tableData" style="height:200px;margin-bottom: 20px;" row-key="id" border>
				<el-table-column prop="statusname" label="地块状态">
				</el-table-column>
				<el-table-column prop="cname" label="地级市">
				</el-table-column>
				<el-table-column prop="zname" label="县级市">
				</el-table-column>
				<el-table-column prop="address" label="行政区">
				</el-table-column>
				<el-table-column prop="block" label="板块">
				</el-table-column>
				<el-table-column prop="locationname" label="地块名称">
				</el-table-column>
				<el-table-column prop="location" label="地块位置">
				</el-table-column>
				<el-table-column prop="usecatename" label="用途">
				</el-table-column>
				<el-table-column prop="usedetail" label="用途类别">
				</el-table-column>
				<el-table-column prop="useyears" label="出让年限">
				</el-table-column>
				<el-table-column prop="acreage" label="面积(㎡)">
				</el-table-column>
				<el-table-column prop="acreagemu" label="面积(亩)">
				</el-table-column>
				<el-table-column prop="volume" label="容积率">
				</el-table-column>
				<el-table-column prop="buildacreage" label="建筑面积 (㎡)">
				</el-table-column>
				<el-table-column prop="maxconacreage" label="最大建筑面积(㎡)">
				</el-table-column>
				<el-table-column prop="minconacreage" label="最小建筑面积(㎡)">
				</el-table-column>
				<el-table-column prop="density" label="建筑密度">
				</el-table-column>
				<el-table-column prop="green" label="绿化率(%)">
				</el-table-column>
				<el-table-column prop="maxheight" label="建筑高度(m)">
				</el-table-column>
			</el-table>

		</div>
	</div>
</template>

<script>

import { getDetail, downloadFileByCate } from "../../api/data";
import polygonpoint from '@/components/bmap-gl/polygonpoint.vue';

import {isgaode} from '@/settings.js'
import leafletPolygonpoint from '@/components/leaflet/leafletPolygonpoint.vue';


export default {
	name: 'FrontendPcBigdataLandlayout',
	components: { polygonpoint,leafletPolygonpoint },

	data() {
		return {
			is_gaode:isgaode,
			id: 0,
			map: {},
			detail: {},
			zoom: 17,
			imglist: [],
			center: [],
			

		};
	},
	computed: {
		tableData: {
			get() {
			
				const data = this.$store.state.currentLandData
				switch(data.status){
					case 1:
						data.statusname = '拟上市'
						break;
					case 2:
						data.statusname = '公告中'
						break;
					case 3:
						data.statusname = '已成交'
						break;
				}
				return [this.$store.state.currentLandData]

			},
			set(v) {
				this.$store.commit('currentLandData', v)
			}
		}
	},
	mounted() {

		if (this.$route.params.id) {
			this.id = this.$route.params.id
		}
		var that = this
		this.getMapDetail()
		
	},

	methods: {
		getImgList() {
			downloadFileByCate({ pid: this.id, category_id: 9 }).then(res => {
				console.log('imglist', res.data)
				this.imglist = res.data
			})

		},

		getMapDetail() {
			var that = this
			return new Promise(function (resolve, reject) {
				getDetail({ id: that.id,is_gaode:isgaode }).then(res => {
					that.detail = res.data
					that.center = [
						res.data.lng_gaode,
						res.data.lat_gaode
					]
					resolve(res.data)
				})

			})

		},


	},
};
</script>

<style lang="scss" scoped>
.container2 {
	width: 100%;
	height: calc(100vh - 188px);
	color: #333;
}

.mapcontainer2 {
	display: flex;
	width: 98%;
	margin: 0 1%;
	height: calc(100vh - 428px);
	margin-top: 10px;

	.bmap-demo {
		flex: 1;
	}
}

.zhibiaotitle {
	width: 98%;
	margin-top: 5px;
	margin-left: 1%;
	height: 40px;
	background: #28A7E1;
	border-radius: 8px 8px 0px 0px;
	font-size: 20px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 40px;
}

.swipercontainer2 {
	bottom: 0;
	right: 0;
	left: 0;
	top: -120%;
	z-index: 10;
}
.tabledata{
	width:98%;
	
	margin-left:1%;
}
</style>