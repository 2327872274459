<template>
  <!-- <el-bmap vid="landlayout" :zoom="zoom" :center="center" :events="events" class="bmap-demo">
    </el-bmap> -->
  <div class="vue-leaflet">
    
      <div class="plugin">
          <div :class="{'pbtn':true, 'activebtn':'vector'=== currentMapLayer.type}" @click="toggleMapType('vector')">矢量地图</div>
          <div :class="{'pbtn':true, 'activebtn':'satellite'=== currentMapLayer.type}" @click="toggleMapType('satellite')">影像地图</div>
      </div>
     
     
    <l-map
      style="width: 100%; height: 100%; z-index: 10"
      :zoom="zoom"
      :center="centerpoint"
    >
      
         <l-tile-layer :url="currentMapLayer.url" ></l-tile-layer>
         <l-tile-layer :url="currentMapLayer.texturl" :attribution="currentMapLayer.attribution"></l-tile-layer>

      <l-marker
        v-if="detail.lat_gaode && detail.lng_gaode"
        :name="detail.name"
        :icon="icon(detail.usecate)"
        :lat-lng="[detail.lat_gaode, detail.lng_gaode]"
        :offset="[8, 0]"
      >
      </l-marker>

      <l-polygon
         v-for="item in polygonpath"
         :key="item.id"
        :lat-lngs="item.path"
        :color="item.filecolor"
        :fill-color="item.filecolor"
        :fill-opacity="0.6"
      >
      </l-polygon>
      
    </l-map>
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LPolygon,
  LIcon,
} from "vue2-leaflet";
import { vectorMapLayer,satelliteMapLayer,leafletmapurl,leafletmaptexturl,leafletmapyunxuanurl, isgaode } from "@/settings.js";

import { latLng, icon } from "leaflet";

export default {
  name: "polygonpoint",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LPolygon,
    LIcon,
  },
  props: {
    zoom: {
      type: Number,
      default: 18,
    },
    center: {
      type: Array,
      default: () => {
        return [0, 0];
      },
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    detail: {
      handler(val) {
        console.log("detail===", val);
        if(val){
            this.centerpoint = L.latLng(
            parseFloat(val.lat_gaode),
            parseFloat(val.lng_gaode)
            );
            this.markerLatLng = L.latLng(
            parseFloat(val.lat_gaode),
            parseFloat(val.lng_gaode)
            );

            this.polygondata = this.handerPolygondata(val.id,val.set_gaode);
        }
       
      },
      deep: true,
    },
  },

  data() {
    return {
      polygonpath: [],
      markerLatLng: L.latLng(),
      centerpoint: L.latLng(),
      crs: L.CRS.EPSG3857, // 使用的是EPSG:3857坐标系
       //矢量地图对象
       vectorMapLayer:vectorMapLayer ,
      //卫星地图数据对象
      satelliteMapLayer: satelliteMapLayer,
      //当前地图图层
      currentMapLayer:{url:'',texturl:'',attribution:'',type:'vector'},
      url: leafletmapurl,
      attribution: "",

      staticAnchor: [16, 37],
    };
  },
  created() {
     this.currentMapLayer = this.vectorMapLayer;
     this.currentMapLayer.type = 'vector'
  },

  methods: {
     toggleMapType(type) {
      if (type === 'vector') {
        this.currentMapLayer = this.vectorMapLayer;
        this.currentMapLayer.type = 'vector'
      } else if (type === 'satellite') {
        this.currentMapLayer = this.satelliteMapLayer;
        this.currentMapLayer.type = 'satellite'
      }
    },



    handerPolygondata(id,allset) {
      let polygonpath = [];
      const setObj = JSON.parse(allset);
      console.log('setObj',setObj)
      const rowpolygonpath = setObj.map((itemSingle) => {
        // data 是一个多变形的数据
        // 处理一个多变形的方法
        const singlepath = this.handleSingleData(id, itemSingle);
        polygonpath.push(singlepath);
      });
      this.polygonpath  = polygonpath
    },

    handleSingleData(id, data) {
      let obj = {
        id: id,
        path: [],
        filecolor: "#000",
        strokcolor: "#409EFF",
        weight: this.strokeWeight,
        strokeopacity: this.strokeOpacity,
        fillopacity: this.fillOpacity,
      };
      const pointobj = data.lnglat.split(";");
      const pointArr = pointobj.map((point) => {
        const lnglat = point.split(",");
        return [ parseFloat(lnglat[1]) ,parseFloat(lnglat[0])]
      });
      obj.path = pointArr;
      obj.filecolor = data.color;

      return obj;
    },

    icon(num) {
      return icon({
        iconUrl: require("@/assets/type" + num + ".png"),
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.plugin{
  height:0px;
  width:120px;
  display: flex;
  background: #fff;
  position: relative;
  float: left;
  top:20px;
  z-index: 1000;
  left:60px;
  .pbtn{
    width:60px;
    height:20px;
    color:#000;
    font-weight: bold;
    line-height: 20px;
    background: #fff;
    border: solid 1px #FFF;
    cursor: pointer;
  }
  .activebtn{
    color:#1384cf;
    border: solid 1px #1384cf;
  }
}


/* 隐藏国企以及版权标识 */
::v-deep .leaflet-control-attribution,
.leaflet-control {
  display: none !important;
}

.vue-leaflet {
  width: 100%;
  height: 100%;
  background: red;
}
</style>