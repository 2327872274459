<template>
    <el-bmap vid="landlayout" :zoom="zoom" :center="center" :events="events" class="bmap-demo">
    </el-bmap>
	
</template>

<script>

import VueBMap from "vue-bmap-gl";
let bmapManager = new VueBMap.BMapManager();

export default {
	name: 'polygonpoint',
	components: { VueBMap },
    props:{
        zoom: 17,
        center: [],
        detail:{
            type: Object,
            default: () => { return {} }
        }
    },
    watch:{
        detail:{
            handler(val){
                this.drawPoint(val)
                this.createOverlay(val)
            },
            deep: true,
            immediate: true
        }
    },

	data() {
		return {
			id: 0,
			map: {},
			events: {
				init: (o) => {
					var mapType1 = new BMapGL.MapTypeControl({ mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP] });
					o.addControl(mapType1);
					this.map = o
					var scaleCtrl = new BMapGL.ScaleControl();  // 添加比例尺控件
					this.map.addControl(scaleCtrl);
					var zoomCtrl = new BMapGL.ZoomControl();  // 添加缩放控件
					this.map.addControl(zoomCtrl);

				},
			},

		};
	},


	methods: {
	
		//画点
		drawPoint(itemdata) {

			const type5 = require('@/assets/point.png')

			const myIcon = new BMapGL.Icon(type5, new BMapGL.Size(50, 110), {
				imageOffset: new BMapGL.Size(0, 0)   // 设置图片偏移   
			})

			// 创建标注对象并添加到地图  
			const marker = new BMapGL.Marker(new BMapGL.Point(parseFloat(itemdata.lng), parseFloat(itemdata.lat)), { icon: myIcon });
			marker.id = itemdata.id
			this.map.addOverlay(marker);
		},


		// 创建一个多变形
		createOverlay(data) {

			const allset = data.set
			const setObj = JSON.parse(allset)
			setObj.map(item => {
				// data 是一个多变形的数据
				// 画一个多变形的方法
				this.createPolygon(item, data.id)
			})


		},

		createPolygon(data, id) {
			const pointobj = data.lnglat.split(';')
			const pointArr = pointobj.map(point => {
				const lnglat = point.split(',')
				return new BMapGL.Point(lnglat[0], lnglat[1])
			})

			const polygonAttribute = {

				strokeWeight: 2,
				strokeOpacity: 0.5,
				strokeColor: '#28A7E1',
				fillColor: data.color,
				fillOpacity: 1
			}

			const overlay = new BMapGL.Polygon(pointArr, polygonAttribute)

			overlay.mode = 'polygon'
			overlay.color = data.color
			overlay.index = data.index

			if (this.index < data.index) { // 更新当前的index
				this.index = data.index
			}
			overlay.id = id

			this.map.addOverlay(overlay)

		},


	},
};
</script>

<style lang="scss" scoped>

</style>