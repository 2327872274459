import request from '@/utils/request'
// import requestMock from '@/utils/requestMock'
//mock数据使用  requestMock需要换成request 删除此行


//获取周边配套和宗地风险点的地图接口
export function zhoubianData(params) {
	return request({
		url: '/frontend/bigdata/landdata/map-spot',
		method: 'post',
		params
	})
}


//获取城市的经纬度
export function getCityLnglat(params) {
	return request({
		url: '/frontend/bigdata/dcity/detail',
		method: 'post',
		params
	})
}


//获取楼盘数据
export function loupanData(params) {
	return request({
		url: '/frontend/bigdata/landdata/compete-list',
		method: 'post',
		params
	})
}





//获取资料
export function downloadFileByCate(params) {
	return request({
		url: '/frontend/bigdata/landdata/file',
		method: 'post',
		params
	})
}



//获取土拍大数据城市
// export function citylist(params) {	
// 	return requestMock({
// 		url: '/mock/datacity.json',
// 		method: 'get',
// 		params
// 	})
// }

export function citylist(params) {
	return request({
		url: '/frontend/bigdata/landdata/city-list',
		method: 'post',
		params
	})
}

export function datadetail(params) {
	return request({
		url: '/frontend/bigdata/landdata/detail',
		method: 'post',
		params
	})
}


export function getDetail(params) {
	return request({
		url: '/frontend/bigdata/landdata/map-detail',
		method: 'post',
		params
	})
}


/**
 *获取地图数据
 * @param {*} params 
 * @returns 
 */
export function mapData(params) {


	return request({
		url: '/frontend/bigdata/landdata/map-list',
		method: 'post',
		params
	})

}


/**
 *获取地图统计区数据
 * @param {*} params 
 * @returns 
 */
 export function mapCount(params) {

	return request({
		url: '/frontend/bigdata/landdata/map-count',
		method: 'post',
		params
	})

}



//获取使用年限列表
export function useYearList(params) {
	return request({
		url: '/frontend/bigdata/landdata/useyears',
		method: 'post',
		params
	})
}





export function useCateList(params) {
	return request({
		url: '/frontend/bigdata/landdata/usecate',
		method: 'post',
		params
	})
}




export function FiledData(params) {
	return request({
		url: '/frontend/bigdata/landdata/form-columns',
		method: 'post',
		params
	})
}

//获取table数据
// export function table(params) {	
// 	return requestMock({
// 		url: '/mock/datatable.json',
// 		method: 'get',
// 		params
// 	})
// }

export function table(params) {
	return request({
		url: '/frontend/bigdata/landdata/table-list',
		method: 'post',
		params
	})
}



//下载excel数据
export function downloadExcel(params) {
	return requestMock({
		url: '/mock/excel.json',
		method: 'get',
		params
	})
}




//下载单条压缩文件
export function downloadZip(params) {
	return requestMock({
		url: '/mock/zip.json',
		method: 'get',
		params
	})
}



//下载文件  参数 id  
export function nextLevelCity1(params) {
	return request({
		url: '',
		method: 'post',
		params
	})
}